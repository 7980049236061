import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"
import Header from "../components/header.component"

import imgStudents from "../images/students1.jpg"
import imgDanBioPic from "../images/dan-bio-home.jpg"
import imgPhotoBar1 from "../images/photo-bar-1.jpg"
import imgPhotoBar2 from "../images/photo-bar-2.jpg"
import imgPhotoBar3 from "../images/photo-bar-3.jpg"
import imgPhotoBar4 from "../images/photo-bar-4.jpg"
import imgOfferingInPerson from "../images/in-person-lessons.jpg"
import imgOfferingTraining from "../images/training.jpg"

import "./index.styles.scss"

const IndexPage = () => (
  <Layout showHeader={false}>
    <Seo title="Parasky Flute Studios | Flute Lessons, Ensembles, Workshops" />
    <section className="hero hero--main is-large is-dark">
      <Header theme="dark" />
      <div className="container hero-body is-flex-direction-column is-justify-content-center has-text-shadow">
        <h1 className="title is-size-2-mobile is-size-1-desktop has-text-white is-uppercase has-text-centered">
          Flute Lessons
        </h1>
        <h2 className="subtitle is-size-4 is-size-3-desktop mt-3 has-text-white has-text-centered">
          Flute Choir. Flute Ensembles. Community Flute Workshops.
        </h2>
      </div>
    </section>
    <section className="index__intro container">
      <div className="columns is-centered">
        <div className="column">
          <div className="intro__content content is-size-6 container--text">
            <aside className="menu">
              <h3 className="is-size-5">
                The Parasky Flute Studios offer private flute lessons in the
                greater Pittsburgh area at two locations. Explore all the{" "}
                <span className="has-text-weight-bold is-uppercase">
                  possibilities
                </span>{" "}
                that studying at the Parasky Flute Studios can create.
              </h3>
              <h4 className="menu-label subtitle has-text-weight-bold is-size-4 mt-6">
                Do you want to
              </h4>
              <ul className="menu-list">
                <li>
                  Have{" "}
                  <span className="has-text-weight-bold is-uppercase">fun</span>{" "}
                  while learning how to improve your playing?
                </li>
                <li>
                  Understand{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    rhythm
                  </span>
                  ?
                </li>
                <li>
                  <span className="has-text-weight-bold is-uppercase">
                    Discover
                  </span>{" "}
                  your best flute sound?
                </li>
                <li>
                  Join a{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    community
                  </span>{" "}
                  of awesome flute students?
                </li>
                <li>
                  Make it into your school’s{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    wind ensemble
                  </span>
                  ?
                </li>
                <li>
                  Learn{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    organizational
                  </span>{" "}
                  skills?
                </li>
                <li>
                  <span className="has-text-weight-bold is-uppercase">
                    March
                  </span>{" "}
                  in the Macy's Thanksgiving Day Parade?
                </li>
                <li>
                  Perform in an{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    amazing
                  </span>{" "}
                  flute ensemble?
                </li>
                <li>
                  Participate in PMEA{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    festivals
                  </span>
                  ?
                </li>
                <li>
                  Perform on an{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    international
                  </span>{" "}
                  music tour?
                </li>
                <li>
                  Play music because you{" "}
                  <span className="has-text-weight-bold is-uppercase">
                    love it
                  </span>{" "}
                  ?
                </li>
                <li>
                  <span className="has-text-weight-bold is-uppercase">
                    Major
                  </span>{" "}
                  in music for college?
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="column is-one-third">
          <figure className="image mt-2 is-75-percent-mobile-xl">
            <img
              className="bio__pic has-border-radius-2 has-box-shadow"
              src={imgStudents}
              alt="Students at Parasky Flute Studios"
            />
          </figure>
        </div>
      </div>
    </section>
    <section className="index__offerings mt-8 py-8 has-background-grey-lighter">
      <div className="container">
        <h3 className="title pb-4 is-size-1 has-text-centered">Work with Me</h3>
        <div className="columns is-centered">
          <div className="column is-full-mobile is-half-tablet is-one-third-desktop has-text-centered">
            <Link
              to="/lessons"
              className="offering card is-block is-75-percent-mobile-xl"
            >
              <header className="card-header">
                <h4
                  className="card-header-title is-size-4-mobile is-size-5-tablet is-size-4-desktop is-justify-content-center"
                  style={{ height: "120px" }}
                >
                  Flute Lessons
                </h4>
              </header>
              <div
                className="card-content px-0 py-0"
                style={{ backgroundImage: "url(" + imgOfferingInPerson + ")" }}
              >
                <div className="is-overlay p-4 is-flex is-align-items-center is-justify-content-center has-text-centered is-size-6 is-size-5-widescreen has-text-white">
                  Weekly lessons available in both Murrysville and Wexford.
                  Attend in-person or remotely from anywhere in the world.
                </div>
              </div>
              <footer className="card-footer is-justify-content-center py-5">
                <button target="_blank" className="button is-link">
                  Book a Private Lesson
                </button>
              </footer>
            </Link>
          </div>
          <div className="column is-full-mobile is-half-tablet is-one-third-desktop has-text-centered">
            <a
              href="https://www.danparasky.com/guides"
              target="_blank"
              rel="noreferrer"
              className="offering card is-block is-75-percent-mobile-xl"
            >
              <header className="card-header">
                <h4
                  className="card-header-title is-size-4-mobile is-size-5-tablet is-size-4-desktop is-justify-content-center"
                  style={{ height: "120px" }}
                >
                  Studio Development for Teachers
                </h4>
              </header>
              <div
                className="card-content px-0 py-0"
                style={{ backgroundImage: "url(" + imgOfferingTraining + ")" }}
              >
                <div className="is-overlay p-4 is-flex is-align-items-center is-justify-content-center has-text-centered is-size-6 is-size-5-widescreen has-text-white">
                  Explore methods that will improve your studio offerings while
                  building a sense of community for your students.
                </div>
              </div>
              <footer className="card-footer is-justify-content-center py-5">
                <button target="_blank" className="button is-link">
                  View Guides
                </button>
              </footer>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="index__bio container my-6">
      <div className="columns is-centered">
        <div className="column is-one-third">
          <figure className="image mt-2 is-75-percent-mobile-xl">
            <img
              className="bio__pic has-border-radius-2 has-box-shadow"
              src={imgDanBioPic}
              alt="Dan Parasky - Owner, Instructor"
            />
          </figure>
        </div>
        <div className="column">
          <div className="bio__content content is-size-6 container--text">
            <h2>Hello!</h2>
            <p>
              I’m Dan Parasky and running a private studio has been my passion
              for as long as I can remember. When I first tried to find a
              private flute teacher, I had a difficult time finding a qualified
              instructor that was the right fit for me.
            </p>
            <p>
              I searched from the ages of 12-16 until I finally stumbled upon
              the teacher that would change my life and by the end of that first
              lesson I knew that running a private studio was my destiny!
            </p>
            <p>
              I learned so much about how to connect with students, run lessons
              and uncover ways to bring out the best in anyone musically. I love
              inspiring people to find their inner “flute nerd”, teaching
              students to organize their practice time with techniques that
              apply to real life and how to collaborate with other studio
              members in our personal community.
            </p>
            <p>
              <Link to="/about" className="button is-primary arrow-right-enlarge">
                Learn more about Dan <FaArrowRight className="ml-3" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="photo-bar">
      <div className="columns is-mobile is-multiline">
        <div className="column is-one-quarter-desktop is-half-tablet is-half-mobile p-0">
          <figure className="image">
            <img src={imgPhotoBar1} alt="Students performing in costume" />
          </figure>
        </div>
        <div className="column is-one-quarter-desktop is-half-tablet is-half-mobile p-0">
          <figure className="image">
            <img src={imgPhotoBar2} alt="Students Performing" />
          </figure>
        </div>
        <div className="column is-one-quarter-desktop is-half-tablet is-half-mobile p-0">
          <figure className="image">
            <img src={imgPhotoBar3} alt="Students Performing" />
          </figure>
        </div>
        <div className="column is-one-quarter-desktop is-half-tablet is-half-mobile p-0">
          <figure className="image">
            <img src={imgPhotoBar4} alt="Students Performing" />
          </figure>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
